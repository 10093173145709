// Imports
import AxiosConfig from './LensAxiosConfig'

// Fetch AD
const getLensGroups = async (group, page) => {
  const queryString = group
    ? `?by=${group}&page=${page}`
    : `?page=${page}`

  const { data } = await AxiosConfig.get(
    `/ads/group${queryString}`
  )

  return data
}

export default {
  getLensGroups
}
