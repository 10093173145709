<template>
  <div class="lens-group-row pl-4">
    <div class="flex items-center gap-4">
      <!-- Ad Image Preview -->
      <div class="w-12 h-12 rounded overflow-hidden">
        <img
          :src="ad.ad_image_url"
          :alt="ad.ad_name"
          class="w-full h-full object-cover"
        >
      </div>

      <!-- Ad Information -->
      <div class="flex flex-col flex-grow">
        <div class="flex items-center gap-2">
          <span class="font-medium">{{ ad.ad_name }}</span>
          <span
            class="px-2 py-0.5 rounded-full text-xs"
            :class="statusClass"
          >
            {{ ad.ad_status }}
          </span>
          <span class="px-2 py-0.5 rounded-full text-xs bg-gray-100 text-gray-800">
            {{ ad.ad_type }}
          </span>
        </div>
        <span class="text-sm text-gray-600">{{ ad.ad_copy }}</span>
        <!-- URL Preview -->
        <a
          :href="ad.destination_url"
          target="_blank"
          class="text-sm text-blue-600 hover:underline mt-1"
        >
          {{ formatUrl(ad.destination_url) }}
        </a>
      </div>

      <!-- Ad Metrics - adding borders -->
      <div class="flex items-center gap-6 divide-x divide-gray-200">
        <!-- First Column -->
        <div class="flex flex-col gap-2 pr-6">
          <div class="text-sm">
            <span class="font-medium">${{ formatNumber(ad.spend) }}</span>
            <span class="text-gray-600 ml-1">{{ ad.currency }}</span>
          </div>
          <div class="text-sm">
            <span class="font-medium">{{ formatNumber(ad.clicks) }}</span>
            <span class="text-gray-600 ml-1">clicks</span>
          </div>
          <div class="text-sm">
            <span class="font-medium">{{ formatNumber(ad.impressions) }}</span>
            <span class="text-gray-600 ml-1">impressions</span>
          </div>
        </div>

        <!-- Second Column -->
        <div class="flex flex-col gap-2 px-6">
          <div class="text-sm">
            <span class="font-medium">${{ formatNumber(ad.conversion_value) }}</span>
            <span class="text-gray-600 ml-1">conv. value</span>
          </div>
          <div class="text-sm">
            <span class="font-medium">{{ formatNumber(ad.ctr) }}%</span>
            <span class="text-gray-600 ml-1">CTR</span>
          </div>
          <div class="text-sm">
            <span class="font-medium">{{ formatNumber(ad.purchases) }}</span>
            <span class="text-gray-600 ml-1">purchases</span>
          </div>
        </div>

        <!-- Third Column -->
        <div class="flex flex-col gap-2 pl-6">
          <div class="text-sm">
            <span class="font-medium">{{ formatNumber(ad.engagements) }}</span>
            <span class="text-gray-600 ml-1">engagements</span>
          </div>
          <div class="text-sm">
            <span class="font-medium">{{ formatNumber(ad.three_second_video_view) }}</span>
            <span class="text-gray-600 ml-1">3s views</span>
          </div>
          <div class="text-sm">
            <span class="font-medium">{{ formatNumber(ad.visits) }}</span>
            <span class="text-gray-600 ml-1">visits</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LensGroupRow',
  props: {
    ad: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusClass () {
      return {
        'bg-green-100 text-green-800': this.ad.ad_status === 'ACTIVE',
        'bg-yellow-100 text-yellow-800': this.ad.ad_status === 'PAUSED',
        'bg-red-100 text-red-800': this.ad.ad_status === 'DELETED' || this.ad.ad_status === 'ARCHIVED'
      }
    }
  },
  methods: {
    formatNumber (value) {
      if (Number.isInteger(Number(value))) {
        return Number(value).toLocaleString()
      }
      return Number(value).toFixed(2)
    },
    formatUrl (url) {
      try {
        const urlObj = new URL(url)
        return urlObj.hostname + urlObj.pathname
      } catch {
        return url
      }
    }
  }
}
</script>
