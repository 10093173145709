<template>
  <div
    v-if="lensData"
    class="w-full p-5 border-2 rounded-xl"
  >
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-semibold">
        LENS DATA - Current Grouping: <span class="text-primary-blue-100">{{ currentFilter }}</span>
        <span class="text-sm text-gray-600 ml-2">
          (Page {{ currentPage }} of {{ totalPages }})
        </span>
      </h2>
      <div class="flex gap-2">
        <button
          class="btn mb-2 border text-white bg-primary-blue-100 px-4 py-2 rounded-md hover:bg-primary-blue-200 disabled:opacity-50 disabled:cursor-not-allowed"
          :disabled="currentPage === 1"
          @click="$emit('prev')"
        >
          Previous Page
        </button>
        <button
          class="btn mb-2 border text-white bg-primary-blue-100 px-4 py-2 rounded-md hover:bg-primary-blue-200"
          :disabled="currentPage === totalPages"
          @click="$emit('next')"
        >
          Next Page
        </button>
        <button
          v-if="lensData.length"
          class="btn mb-2 border text-white bg-primary-blue-100 px-4 py-2 rounded-md hover:bg-primary-blue-200"
          @click="$emit('refresh')"
        >
          Refresh
        </button>
      </div>
    </div>

    <LensRow
      v-for="(adGroup, index) of lensData"
      :key="index"
      :ad-group="adGroup"
    />

    <!-- Bottom pagination -->
    <div class="flex justify-end gap-2 mt-4">
      <button
        class="btn mb-2 border text-white bg-primary-blue-100 px-4 py-2 rounded-md hover:bg-primary-blue-200 disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="currentPage === 1"
        @click="$emit('prev')"
      >
        Previous Page
      </button>
      <button
        class="btn mb-2 border text-white bg-primary-blue-100 px-4 py-2 rounded-md hover:bg-primary-blue-200"
        :disabled="currentPage === totalPages"
        @click="$emit('next')"
      >
        Next Page
      </button>
    </div>
  </div>
</template>
<script>
import LensRow from './LensRow.vue'

export default {
  name: 'LensTable',
  components: {
    LensRow
  },
  props: {
    lensData: {
      type: Array,
      default: () => []
    },
    currentFilter: {
      type: String,
      default: 'ad_name'
    },
    totalItems: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    elapsedTime: {
      type: Number,
      required: true
    }
  }
}
</script>
