<template>
  <div
    class="lens-row p-4 border-b border-gray-300 hover:bg-gray-50 cursor-pointer"
    @click="toggleSubGroups"
  >
    <div class="flex justify-between items-start">
      <!-- Ad Name and Status -->
      <div class="flex-1">
        <div class="flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 transform transition-transform duration-200"
            :class="{ 'rotate-90': isExpanded }"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
          <h3 class="font-medium">
            {{ adTitle }}
          </h3>
        </div>
        <div class="flex gap-2 text-sm text-gray-600">
          <span>Ads: {{ activeAdCount }} active, {{ inactiveAdCount }} inactive</span>
          <span>Status: {{ adGroup.ads[0].ad_status }}</span>
        </div>
      </div>

      <!-- Metrics Summary -->
      <div class="flex gap-6">
        <div class="text-right border-r pr-6">
          <div class="text-sm text-gray-600">
            Spend
          </div>
          <div class="font-medium">
            ${{ totalSpend }} {{ adGroup.ads[0].currency }}
          </div>
        </div>
        <div class="text-right border-r pr-6">
          <div class="text-sm text-gray-600">
            Clicks
          </div>
          <div class="font-medium">
            {{ totalClicks }}
          </div>
        </div>
        <div class="text-right border-r pr-6">
          <div class="text-sm text-gray-600">
            Impressions
          </div>
          <div class="font-medium">
            {{ formatNumber(totalImpressions) }}
          </div>
        </div>
        <div class="text-right border-r pr-6">
          <div class="text-sm text-gray-600">
            Conv. Value
          </div>
          <div class="font-medium">
            ${{ totalConversionValue }}
          </div>
        </div>
        <div class="text-right border-r pr-6">
          <div class="text-sm text-gray-600">
            CTR
          </div>
          <div class="font-medium">
            {{ ctr }}%
          </div>
        </div>
        <div class="text-right border-r pr-6">
          <div class="text-sm text-gray-600">
            Purchases
          </div>
          <div class="font-medium">
            {{ totalPurchases }}
          </div>
        </div>
        <div class="text-right border-r pr-6">
          <div class="text-sm text-gray-600">
            Engagements
          </div>
          <div class="font-medium">
            {{ totalEngagements }}
          </div>
        </div>
        <div class="text-right border-r pr-6">
          <div class="text-sm text-gray-600">
            Views
          </div>
          <div class="font-medium">
            {{ formatNumber(totalViews) }}s
          </div>
        </div>
        <div class="text-right">
          <div class="text-sm text-gray-600">
            Visits
          </div>
          <div class="font-medium">
            {{ totalVisits }}
          </div>
        </div>
      </div>
    </div>

    <!-- Add LensGroupRow components with dividers -->
    <div v-if="isExpanded">
      <lens-group-row
        v-for="ad in adGroup.ads"
        :key="ad.ad_id"
        :ad="ad"
        class="mt-4 first:mt-4 border-t border-gray-100 pt-4 bg-blue-100 border rounded-md"
      />
    </div>
  </div>
</template>

<script>
import LensGroupRow from './LensGroupRow.vue'

export default {
  name: 'LensRow',
  components: {
    LensGroupRow
  },
  props: {
    adGroup: {
      type: Object,
      required: true,
      default: () => ({
        ad_name: '',
        ads: []
      })
    }
  },
  data () {
    return {
      isExpanded: false
    }
  },
  computed: {
    adTitle () {
      return this.adGroup.ad_name || this.adGroup.ad_copy || this.adGroup.destination_url || this.adGroup.content_url
    },
    totalSpend () {
      const value = this.adGroup.ads.reduce((sum, ad) => sum + (ad.spend || 0), 0)
      return this.formatNumber(value.toFixed(2))
    },
    totalClicks () {
      return this.adGroup.ads.reduce((sum, ad) => sum + (ad.clicks || 0), 0)
    },
    totalImpressions () {
      return this.adGroup.ads.reduce((sum, ad) => sum + (ad.impressions || 0), 0)
    },
    totalConversionValue () {
      const value = this.adGroup.ads.reduce((sum, ad) => sum + (ad.conversion_value || 0), 0)
      return this.formatNumber(value.toFixed(2))
    },
    ctr () {
      const ctr = (this.totalClicks / this.totalImpressions) * 100
      return isNaN(ctr) ? 0 : ctr.toFixed(1)
    },
    totalPurchases () {
      return this.adGroup.ads.reduce((sum, ad) => sum + (ad.purchases || 0), 0)
    },
    totalEngagements () {
      return this.adGroup.ads.reduce((sum, ad) => sum + (ad.engagements || 0), 0)
    },
    totalViews () {
      return this.adGroup.ads.reduce((sum, ad) => sum + (ad.views || 0), 0)
    },
    totalVisits () {
      return this.adGroup.ads.reduce((sum, ad) => sum + (ad.visits || 0), 0)
    },
    activeAdCount() {
      return this.adGroup.ads.filter(ad => ad.ad_status === 'ACTIVE').length
    },
    inactiveAdCount() {
      return this.adGroup.ads.filter(ad => ad.ad_status !== 'ACTIVE').length
    }
  },
  methods: {
    toggleSubGroups () {
      this.isExpanded = !this.isExpanded
    },
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
