<template>
  <div class="overflow-x-hidden rounded-xl">
    {{ selectedRows.size }}
    <div class="w-full overflow-x-auto ">
      <table>
        <thead>
          <tr class="uppercase">
            <!-- Grouped by column -->
            <th class="flex items-center px-2 py-3 gap-3 sticky-column">
              <input
                type="checkbox"
                class="checkbox-input"
                :checked="areAllRowsSelected"
                @change="toggleAllRows"
              >
              <BaseText
                type="heading"
                size="overline"
                class="text-text-subdued"
              >
                {{ groupedBy }}
              </BaseText>
            </th>
            <!-- Other selected kpis -->
            <th
              v-for="header in selectedKpis"
              :key="header"
              class="p-3"
            >
              <BaseText
                type="heading"
                size="overline"
                class="text-text-subdued text-left"
              >
                {{ header }}
              </BaseText>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in rows"
            :key="index"
          >
            <td class="sticky-column">
              <div class="group-cell grid grid-cols-12 py-1 px-2 gap-x-3 items-center">
                <!-- Left checkbox -->
                <div class="col-span-1">
                  <input
                    :checked="isRowSelected(row.group_by)"
                    class="checkbox-input"
                    type="checkbox"
                    @change="setRowSelected(row.group_by)"
                  >
                </div>
                <!-- Middle info -->
                <div class="col-span-8 ml-1">
                  <div
                    class="flex items-center flex-nowrap gap-2 overflow-x-hidden min-w-0"
                  >
                    <!-- Group image -->
                    <img
                      :src="row.ads[0].ad_image_url"
                      class="rounded-md w-7 h-7 flex-shrink-0"
                    >
                    <!-- Group title & ad count -->
                    <div class="flex-grow min-w-0 whitespace-nowrap">
                      <BaseText
                        class="text-text-muted truncate"
                        size="sm"
                      >
                        {{ row.group_by }}
                      </BaseText>
                      <BaseText
                        class="text-text-normal"
                        size="xs"
                      >
                        {{ row.ads.length }} Ads
                      </BaseText>
                    </div>
                  </div>
                </div>
                <!-- CTA actions -->
                <div class="col-span-2">
                  <div class="flex items-center min-w-0">
                    <!-- Add hover effects -->
                    <div class="p-1 cursor-pointer">
                      <AdDetailsAnimatedIcon class="cursor-pointer text-icon-normal" />
                    </div>
                    <div class="p-1 cursor-pointer">
                      <CreativeInsightIcon class="cursor-pointer text-icon-normal" />
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- Selected KPI Columns -->
            <td
              v-for="kpi in selectedKpis"
              :key="kpi"
            >
              <div class="cell p-3">
                <DecimalCell
                  :data="{value: row[kpi]}"
                  :fixed-to="2"
                />
              </div>
            </td>
          </tr>
        </tbody>
        <!-- Table footer (Net results across all groupings) -->
        <tfoot>
          <tr>
            <td class="grid grid-cols-12 p-3 gap-x-3 items-center sticky-column">
              <!-- TODO @Sam: update to questionMark icon -->
              <div class="w-5 h-5 bg-gray-300 col-span-2 rounded-full" />
              <BaseText
                size="sm"
                type="label"
                class="text-text-muted col-span-10"
              >
                Net Result
              </BaseText>
            </td>
            <td
              v-for="kpi in selectedKpis"
              :key="kpi"
            >
              <div class="cell p-3">
                <DecimalCell
                  :data="{value: netResults[kpi]}"
                  :fixed-to="2"
                />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- Pagination -->
    <div class="mt-3 flex items-center justify-between">
      <div class="flex items-center gap-3">
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          {{ rows.length }} results
        </BaseText>
        <div>
          <BaseText
            class="text-text-normal flex items-center"
            size="sm"
          >
            1-<span>{{ totalPages }}</span> of <span>{{ totalItems }}</span>
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Table cell components
import CurrencyCell from './cells/CurrencyCell.vue'
import StatusCell from './cells/StatusCell.vue'
import DecimalCell from './cells/DecimalCell.vue'
import AdDetailsAnimatedIcon from '../../globals/Icons/AdDetailsAnimatedIcon.vue'
import CreativeInsightIcon from '../../globals/Icons/CreativeInsightIcon.vue'
export default {
  name: 'LensMetricTable',
  components: {
    StatusCell,
    CurrencyCell,
    DecimalCell,
    AdDetailsAnimatedIcon,
    CreativeInsightIcon
  },
  props: {
    selectedKpis: {
      type: Array,
      required: true
    },
    groupedBy: {
      type: String,
      default: () => 'ad_name'
    },
    totalPages: {
      type: Number,
      default: () => 0
    },
    totalItems: {
      type: Number,
      default: () => 0
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedRows: [],
      netResults: {},
      pagination: {},
      rows: []
    }
  },
  computed: {
    areAllRowsSelected () {
      return this.rows.length && this.selectedRows.length === this.rows.length
    }
  },
  watch: {
    data (newVal) {
      this.rows = newVal[0].ad_groups
      console.log(newVal, 'sam')
      this.pagination = {
        pages: newVal.pages,
        total: newVal.total
      }
      this.netResults = {
        ...newVal[0]
      }
    }
  },
  mounted () {
    console.log(this.data)
  },
  methods: {
    setRowSelected (id) {
      if (this.selectedRows.includes(id)) {
        this.selectedRows = this.selectedRows.filter(row => row !== id)
        // this.selectedRows.filter((row) => row.group_by !== id)
      } else {
        this.selectedRows.push(id)
      }
    },
    isRowSelected (id) {
      return this.selectedRows.includes(id)
    },
    toggleAllRows () {
      if (this.areAllRowsSelected) {
        console.log('%c CLEARING', 'font-size: 16px; color: green;')
        this.selectedRows = []
      } else {
        console.log(`%c SETTING ALL ${this.rows.length}`, 'font-size: 16px; color: blue;')
        this.selectedRows = this.rows.map(row => row.group_by)
      }
    }
  }
}
</script>

<style scoped>
/* Table */
table{
  border-collapse: separate;
}

th, td{
  background-color: white;
  border: none;
}

table th:first-child,
table td:first-child {
  border-right: 1px solid #ECEFF3;
}

table th:nth-child(2),
table td:nth-child(2) {
  border-left: none;
}

.cell{
  max-height: 40px;
  height: 100%;
  min-width: 132px;
  max-width: 240px;
}

.group-cell{
  width: 320px;
}
.sticky-column{
  position: sticky;
  left: 0;
  z-index: 2;
}

/* Checkbox */
.checkbox-input {
  box-shadow: 0px 0px 0px 1px #09194821, 0px 1px 2px 0px #09194821;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.checkbox-input:checked {
  background-color: black;
}

/* Rounded table */
/* table {
  border-spacing: 0;
}
:is(td, th) {
  border-block-width: 1px 0;
  border-inline-width: 1px 0;
  &:last-of-type { border-inline-end-width: 1px }
}
th {
  &:first-of-type { border-start-start-radius: 12px }
  &:last-of-type { border-start-end-radius: 12px }
}
tr {
  &:last-of-type {
    & td {
      &:first-of-type { border-end-start-radius: 12px }
      &:last-of-type { border-end-end-radius: 12px }
    }
  }
} */
/* Rounded table */
/* table {
  border-spacing: 0;
}
:is(td, th) {
  border-block-width: 1px 0;
  border-inline-width: 1px 0;
  &:last-of-type { border-inline-end-width: 1px }
}
th {
  &:first-of-type { border-start-start-radius: 12px }
  &:last-of-type { border-start-end-radius: 12px }
}
tr {
  &:last-of-type {
    & td {
      &:first-of-type { border-end-start-radius: 12px }
      &:last-of-type { border-end-end-radius: 12px }
    }
  }
} */

</style>
