<template>
  <div
    class="rounded-md py-1 px-1.5 flex items-center gap-1 w-min"
    :class="data?.value ? 'active-tag' : 'inactive-tag'"
  >
    <div
      class="w-1 h-1 rounded-full"
      :class="data?.value ? 'active-indicator' : 'inactive-indicator'"
    />
    <BaseText
      type="label"
      size="xs"
    >
      {{ data?.value ? 'Active' : 'Inactive' }}
    </BaseText>
  </div>
</template>

<script>
export default {
  name: 'StatusCell',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}

</script>

<style scoped>
.active-tag{
    background-color: #EFFEFA;
    color: #184e44;
}

.inactive-tag{
    background-color: #F6F8FA !important;
    color: #5E6678;
}

.active-indicator{
    background-color: #00A879;
}
.inactive-indicator{
    background-color: #A4ACB9;
}
</style>
