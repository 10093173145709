<template>
  <MainLayout>
    <template #content>
      <div>
        <div class="mb-4">
          <button
            class="btn mb-2 border text-white bg-primary-blue-100 px-4 py-2 rounded-md"
            @click="refreshLensData('ad_name')"
          >
            Group by Name
          </button>
          <button
            class="btn mb-2 border text-white bg-primary-blue-100 px-4 py-2 rounded-md"
            @click="refreshLensData('ad_copy')"
          >
            Group by Copy
          </button>
          <button
            class="btn mb-2 border text-white bg-primary-blue-100 px-4 py-2 rounded-md"
            @click="refreshLensData('destination_url')"
          >
            Group by Landing Page
          </button>
          <button
            class="btn mb-2 border text-white bg-primary-blue-100 px-4 py-2 rounded-md"
            @click="refreshLensData('creative')"
          >
            Group by Creative
          </button>
        </div>
        <LensMetricTable
          v-if="lensData"
          :grouped-by="currentFilter"
          :total-items="totalItems"
          :total-pages="totalPages"
          :selected-kpis="['clicks', 'conversion_value', 'ctr', 'engagements', 'impressions', 'purchases', 'reach', 'roas', 'spend', 'three_second_video_view', 'thumbstop']"
          :data="lensData"
        />
        <div class="h-1 bg-red-500 w-full my-12" />
        <!-- <LensTable
          v-if="lensData"
          :current-filter="currentFilter"
          :lens-data="lensData"
          :total-items="totalItems"
          :total-pages="totalPages"
          :current-page="currentPage"
          :elapsed-time="elapsedTime"
          @next="nextPage"
          @prev="prevPage"
          @refresh="refreshLensData"
        /> -->
      </div>
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from '../../layouts/MainLayout.vue'
import LensTable from '../../components/lens/LensTable.vue'
import LensAPI from '../../api/lens'
import LensMetricTable from '../../components/lens/table/LensMetricTable.vue'

export default {
  name: 'LensTest',
  components: {
    LensTable,
    MainLayout,
    LensMetricTable
  },
  data () {
    return {
      lensData: [],
      currentFilter: 'ad_name',
      currentPage: 1,
      totalItems: 0,
      totalPages: 0,
      elapsedTime: 0
    }
  },
  async mounted () {
    const response = await LensAPI.AdGroups.getLensGroups(this.currentFilter, this.currentPage)
    this.updateDataFromResponse(response)
  },
  methods: {
    updateDataFromResponse (response) {
      this.lensData = response.data
      this.totalItems = response.total
      this.totalPages = response.pages
      this.elapsedTime = response.elapsed_time
    },
    async refreshLensData (group) {
      this.lensData = []
      this.currentFilter = group
      const response = await LensAPI.AdGroups.getLensGroups(group, this.currentPage)
      this.updateDataFromResponse(response)
    },
    async nextPage () {
      this.currentPage++
      const response = await LensAPI.AdGroups.getLensGroups(this.currentFilter, this.currentPage)
      this.updateDataFromResponse(response)
    },
    async prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        const response = await LensAPI.AdGroups.getLensGroups(this.currentFilter, this.currentPage)
        this.updateDataFromResponse(response)
      }
    }
  }
}
</script>
