import { render, staticRenderFns } from "./PlanSettings.vue?vue&type=template&id=4de10006&scoped=true"
import script from "./PlanSettings.vue?vue&type=script&lang=js"
export * from "./PlanSettings.vue?vue&type=script&lang=js"
import style0 from "./PlanSettings.vue?vue&type=style&index=0&id=4de10006&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de10006",
  null
  
)

export default component.exports